<template>
  <v-container fluid>
    <v-data-table
      :search="search"
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="region.subregions"
      item-key="id"
    >
      <!-- outlet template -->
      <template v-slot:item.descript="props">
        <v-btn
          color="blue lighten-1"
          text
          small
          :to="`/region/${props.item.id}`"
        >{{props.item.descript}}</v-btn>
      </template>
      <!-- end -->
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      region: {},
      headers: [
        { text: "Name", value: "descript" },
        { text: "Sales Reps", value: "salesReps" },
        { text: "Sub regions", value: "subRegions" },
        { text: "Total Outlets", value: "totalOutlets" }
      ]
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true
    }
  },
  methods: {
    getData(val) {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/territory/${val}`)
        .then(res => {
          self.region = res;
          self.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>